@use "../../common/all" as *;

.note_box {
  border-radius: 8px;
  border: 1px solid $brown-30;
  background-color: $brown-10;
  padding: 12px;
  display: flex;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  &.orange {
    border-color: $orange-30;
    background-color: $orange-10;
  }
  &.blue {
    border-color: $blue-30;
    background-color: $blue-10;
  }
  &.red {
    border-color: $red-30;
    background-color: $red-10;
  }
  &.green {
    border-color: $green-30;
    background-color: $green-10;
  }
}

.note_content {
  flex: 1 1 auto;
  overflow: hidden;
}

.note_heading {
  color: $black-90;
  text-transform: none;
  font-weight: 300;
  font-size: $font-size-2;
  line-height: 20px;
  letter-spacing: 0;
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
}

.note_icon {
  margin-right: 14px;
  flex: 0 0 auto;
}

.note_copy {
  color: $text-color;
  line-height: 1.4;
  font-size: $font-size-1;
  white-space: pre-line;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
